export const adminSidebarSizes = { small: 169, large: 239 };
export const siteName = 'Virtual Clinic';
export const protectedRouteDirectory = 'clinic';
export const importLegalServerInitialState = {
    searchParams: '',
    batchVariables: {
        matterTypeId: '',
        partnerId: '',
        referrerId: '',
        creationDate: null,
        referrerMatterNumber: '',
    },
};
export const importMattersInitialState = {
    batchVariables: {
        matterTypeId: '',
        partnerId: '',
        referrerId: '',
        creationDate: null,
        referrerMatterNumber: '',
    },
    mapping: {
        columns: [],
    },
    id: null, // file ID returned by imports/columns
    // store document data locally TODO: not sure if these is actually needed yet
    documents: [],
    // returned by API, used by column mapping
    header: [],
    firstRow: [],
};
export const createMatterInitialState = {
    matter: {
        matterTypeId: '',
        partnerId: '',
        referrerId: '',
        creationDate: null,
        referrerMatterNumber: '',
        matterNumber: '',
    },
    applicant: {
        firstName: '',
        middleName: '',
        lastName: '',
        otherName: '',
        dob: null,
        email: '',
        phone: '',
        city: '',
        state: '',
        countryOfOrigin: '',
    },
    documents: [], // { id: #, name: '', title: '',}
};
export const statusOptions = [
    {
        key: 'open',
        value: 'Open',
    }, {
        key: 'assigned',
        value: 'Assigned',
    }, {
        key: 'underReview',
        value: 'Under Review',
    }, {
        key: 'closed',
        value: 'Closed',
    }];
