import React from 'react';
import { format } from 'date-fns';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';

import { protectedRouteDirectory } from '../constants';
import WebClient from '../utils/web-client';
import prepareContentType from '../utils/prepare-content-type';
import removeEmpty from '../utils/remove-empty-keys';
import MatterTypes from '../action-types/matters';

import { APIError, APISuccess, GetAppConfig } from './app';
import { GetUser } from './user';
import {
    getImportLegalServerData,
    getImportMattersFormData,
    getCreateMatterFormData,
    getPaginatedMattersParams,
    getPaginatedMatterTypesParams,
} from '../selectors/matters';
import { getRole } from '../selectors/auth';
import { getCreateMatterFormQuestions } from '../selectors/app';

export const MarkMatterMessagesRead = (payload) => ({
    type: MatterTypes.MARK_MATTER_MESSAGES_READ,
    payload,
});

const GetMatterFullBegin = () => ({
    type: MatterTypes.GET_MATTER_FULL_BEGIN,
});
const GetMatterFullSuccess = (payload) => ({
    type: MatterTypes.GET_MATTER_FULL_SUCCESS,
    payload,
});
const GetMatterFullError = () => ({
    type: MatterTypes.GET_MATTER_FULL_ERROR,
});

export const GetMatterFull = (id) => async (dispatch) => {
    dispatch(GetMatterFullBegin());

    try {
        const { data } = await WebClient.get(`/matters/${id}/full`);
        dispatch(GetMatterFullSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetMatterFullError());
    }
};

const AddTimeRecordBegin = () => ({
    type: MatterTypes.ADD_TIME_RECORD_BEGIN,
});
const AddTimeRecordSuccess = () => ({
    type: MatterTypes.ADD_TIME_RECORD_SUCCESS,
});
const AddTimeRecordError = () => ({
    type: MatterTypes.ADD_TIME_RECORD_ERROR,
});

export const AddTimeRecord = (payload) => async (dispatch) => {
    dispatch(AddTimeRecordBegin());

    try {
        await WebClient.post('/times', payload);
        dispatch(AddTimeRecordSuccess('Time successfull added.'));
        dispatch(GetMatterFull(payload.matterId));
    } catch (error) {
        dispatch(APIError('There was an error adding time to the matter.'));
        dispatch(AddTimeRecordError());
    }
};

const UpdateTimeRecordBegin = () => ({
    type: MatterTypes.UPDATE_TIME_RECORD_BEGIN,
});
const UpdateTimeRecordSuccess = () => ({
    type: MatterTypes.UPDATE_TIME_RECORD_SUCCESS,
});
const UpdateTimeRecordError = () => ({
    type: MatterTypes.UPDATE_TIME_RECORD_ERROR,
});

export const UpdateTimeRecord = (id, matterId, payload) => async (dispatch) => {
    dispatch(UpdateTimeRecordBegin());

    try {
        await WebClient.patch(`/times/${id}`, payload);
        dispatch(UpdateTimeRecordSuccess('Time successfull updated.'));
        dispatch(GetMatterFull(matterId));
    } catch (error) {
        dispatch(APIError('There was an error updating the time on the matter.'));
        dispatch(UpdateTimeRecordError());
    }
};

const DeleteTimeRecordBegin = () => ({
    type: MatterTypes.DELETE_TIME_RECORD_BEGIN,
});
const DeleteTimeRecordSuccess = () => ({
    type: MatterTypes.DELETE_TIME_RECORD_SUCCESS,
});
const DeleteTimeRecordError = () => ({
    type: MatterTypes.DELETE_TIME_RECORD_ERROR,
});

export const DeleteTimeRecord = (id, matterId) => async (dispatch) => {
    dispatch(DeleteTimeRecordBegin());

    try {
        await WebClient.delete(`/times/${id}`);
        dispatch(DeleteTimeRecordSuccess());
        dispatch(GetMatterFull(matterId));
    } catch (error) {
        dispatch(APIError('There was an error deletkng the time on the matter.'));
        dispatch(DeleteTimeRecordError());
    }
};

export const GetMatterMessagesBegin = () => ({
    type: MatterTypes.GET_MATTER_MESSAGES_BEGIN,
});
const GetMatterMessagesSuccess = (payload) => ({
    type: MatterTypes.GET_MATTER_MESSAGES_SUCCESS,
    payload,
});
const GetMatterMessagesError = () => ({
    type: MatterTypes.GET_MATTER_MESSAGES_ERROR,
});

export const GetMatterMessages = (id) => async (dispatch) => {
    dispatch(GetMatterMessagesBegin());

    try {
        const { data } = await WebClient.get(`/matters/${id}/messages`);
        dispatch(GetMatterMessagesSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetMatterMessagesError());
    }
};

export const SendMatterMessageBegin = () => ({
    type: MatterTypes.SEND_MATTER_MESSAGE_BEGIN,
});
const SendMatterMessageSuccess = () => ({
    type: MatterTypes.SEND_MATTER_MESSAGE_SUCCESS,
});
const SendMatterMessageError = () => ({
    type: MatterTypes.SEND_MATTER_MESSAGE_ERROR,
});

export const SendMatterMessage = (payload) => async (dispatch) => {
    dispatch(SendMatterMessageBegin());

    try {
        // payload = { matterId, body }
        await WebClient.post('/messages', payload);
        dispatch(SendMatterMessageSuccess());
        dispatch(GetMatterMessages(payload.matterId));
    } catch (error) {
        dispatch(APIError(`Error: ${error?.response?.data?.message}`));
        dispatch(SendMatterMessageError());
    }
};

const ExportMatterBegin = () => ({
    type: MatterTypes.EXPORT_MATTER_BEGIN,
});
const ExportMatterSuccess = () => ({
    type: MatterTypes.EXPORT_MATTER_SUCCESS,
});
const ExportMatterError = () => ({
    type: MatterTypes.EXPORT_MATTER_ERROR,
});

export const ExportMatter = (id) => async (dispatch) => {
    dispatch(ExportMatterBegin());

    try {
        const { data } = await WebClient.get(`/matters/${id}/export`);

        // const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = data.url;
        const date = format(new Date(), 'MM/dd/yyyy');
        link.setAttribute('download', `matter-${id}-summary-${date}.pdf`);
        document.body.appendChild(link);
        link.click();
        dispatch(ExportMatterSuccess());
    } catch (error) {
        dispatch(APIError());
        dispatch(ExportMatterError());
    }
};

const GetMattersReportBegin = () => ({
    type: MatterTypes.GET_MATTERS_REPORT_BEGIN,
});
const GetMattersReportSuccess = (payload) => ({
    type: MatterTypes.GET_MATTERS_REPORT_SUCCESS,
    payload,
});
const GetMattersReportError = () => ({
    type: MatterTypes.GET_MATTERS_REPORT_ERROR,
});

export const GetMattersReport = (params) => async (dispatch) => {
    dispatch(GetMattersReportBegin());

    try {
        const { data } = await WebClient.get(
            '/report/matters',
            { params },
        );

        if (params.output === 'count') {
            dispatch(GetMattersReportSuccess(data));
        } else {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            const date = format(new Date(), 'MM/dd/yyyy');
            link.setAttribute('download', `matter-report-${date}.csv`);
            document.body.appendChild(link);
            link.click();
            dispatch(GetMattersReportSuccess());
        }
    } catch (error) {
        dispatch(APIError());
        dispatch(GetMattersReportError());
    }
};

// Lawyer only functionality
const ReportIssueBegin = () => ({
    type: MatterTypes.REPORT_ISSUE_BEGIN,
});
const ReportIssueSuccess = () => ({
    type: MatterTypes.REPORT_ISSUE_SUCCESS,
});
const ReportIssueError = () => ({
    type: MatterTypes.REPORT_ISSUE_ERROR,
});

export const ReportIssue = (matterId, data, onSuccess) => async (dispatch) => {
    dispatch(ReportIssueBegin());
    try {
        await WebClient.post(`/matters/${matterId}/issue`, data);
        if (onSuccess) onSuccess();
        dispatch(ReportIssueSuccess());
        dispatch(GetMatterFull(matterId));
        dispatch(APISuccess('Your issue has been reported to the admin. They will be in contact.'));
    } catch (error) {
        dispatch(APIError('Error reporting an issue with the matter.'));
        dispatch(ReportIssueError());
    }
};

// Admin only function
const GetMattersJSONBegin = () => ({
    type: MatterTypes.GET_MATTERS_JSON_BEGIN,
});
const GetMattersJSONSuccess = (payload) => ({
    type: MatterTypes.GET_MATTERS_JSON_SUCCESS,
    payload,
});
const GetMattersJSONError = () => ({
    type: MatterTypes.GET_MATTERS_JSON_ERROR,
});

export const GetMattersJSON = () => async (dispatch) => {
    dispatch(GetMattersJSONBegin());
    try {
        const { data } = await WebClient.get('/matters/json');
        dispatch(GetMattersJSONSuccess(data.url));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetMattersJSONError());
    }
};

// admin site management functionality
const GetMatterTypeColorsBegin = () => ({
    type: MatterTypes.GET_MATTER_TYPE_COLORS_BEGIN,
});
const GetMatterTypeColorsSuccess = (payload) => ({
    type: MatterTypes.GET_MATTER_TYPE_COLORS_SUCCESS,
    payload,
});
const GetMatterTypeColorsError = () => ({
    type: MatterTypes.GET_MATTER_TYPE_COLORS_ERROR,
});

export const GetMatterTypeColors = () => async (dispatch) => {
    dispatch(GetMatterTypeColorsBegin());
    try {
        const { data } = await WebClient.get('/matter-colors');
        dispatch(GetMatterTypeColorsSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetMatterTypeColorsError());
    }
};
const GetMatterTypesBegin = () => ({
    type: MatterTypes.GET_MATTER_TYPES_BEGIN,
});
const GetMatterTypesSuccess = (payload) => ({
    type: MatterTypes.GET_MATTER_TYPES_SUCCESS,
    payload,
});
const GetMatterTypesError = () => ({
    type: MatterTypes.GET_MATTER_TYPES_ERROR,
});

export const GetMatterTypes = () => async (dispatch) => {
    dispatch(GetMatterTypesBegin());
    try {
        const { data } = await WebClient.get('/matter-types');
        dispatch(GetMatterTypesSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetMatterTypesError());
    }
};

export const UpdatePaginatedMatterTypesParams = (payload) => ({
    type: MatterTypes.UPDATE_PAGINATED_MATTER_TYPES_PARAMS,
    payload,
});

const GetPaginatedMatterTypesBegin = () => ({
    type: MatterTypes.GET_PAGINATED_MATTER_TYPES_BEGIN,
});
const GetPaginatedMatterTypesSuccess = (payload) => ({
    type: MatterTypes.GET_PAGINATED_MATTER_TYPES_SUCCESS,
    payload,
});
const GetPaginatedMatterTypesError = () => ({
    type: MatterTypes.GET_PAGINATED_MATTER_TYPES_ERROR,
});

export const GetPaginatedMatterTypes = () => async (dispatch, getState) => {
    dispatch(GetPaginatedMatterTypesBegin());

    const paginatedOrgsParams = getPaginatedMatterTypesParams(getState());
    const payload = { ...paginatedOrgsParams };
    delete payload.page;
    delete payload.pageSize;
    delete payload.results;
    delete payload.total;

    try {
        const { data } = await WebClient.post(`/matter-types/${paginatedOrgsParams.page}/${paginatedOrgsParams.pageSize}`, payload);
        dispatch(GetPaginatedMatterTypesSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetPaginatedMatterTypesError());
    }
};

const AddMatterTypeBegin = () => ({
    type: MatterTypes.ADD_MATTER_TYPE_BEGIN,
});
const AddMatterTypeSuccess = () => ({
    type: MatterTypes.ADD_MATTER_TYPE_SUCCESS,
});
const AddMatterTypeError = () => ({
    type: MatterTypes.ADD_MATTER_TYPE_ERROR,
});

export const AddMatterType = (data, onSuccess) => async (dispatch) => {
    dispatch(AddMatterTypeBegin());

    try {
        await WebClient.post('/matter-types', data);
        dispatch(AddMatterTypeSuccess());
        dispatch(APISuccess('Matter Type successfully added.'));
        dispatch(GetMatterTypes());
        dispatch(GetMatterTypeColors());
        dispatch(GetPaginatedMatterTypes()); // assumes we are on the site management / matter types page
        dispatch(GetAppConfig()); // keeps our "options" list current for creating new matters
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(AddMatterTypeError());
        dispatch(APIError('Error adding matter type.'));
    }
};

const EditMatterTypeBegin = () => ({
    type: MatterTypes.EDIT_MATTER_TYPE_BEGIN,
});
const EditMatterTypeSuccess = () => ({
    type: MatterTypes.EDIT_MATTER_TYPE_SUCCESS,
});
const EditMatterTypeError = () => ({
    type: MatterTypes.EDIT_MATTER_TYPE_ERROR,
});

export const EditMatterType = (matterTypeId, data, onSuccess) => async (dispatch) => {
    dispatch(EditMatterTypeBegin());

    try {
        await WebClient.patch(`/matter-types/${matterTypeId}`, data);
        dispatch(EditMatterTypeSuccess());
        dispatch(GetMatterTypes());
        dispatch(GetMatterTypeColors());
        dispatch(GetPaginatedMatterTypes());
        dispatch(GetAppConfig()); // keeps our "options" list current for creating new matters
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError());
        dispatch(EditMatterTypeError());
    }
};

const DeleteMatterTypeBegin = () => ({
    type: MatterTypes.DELETE_MATTER_TYPE_BEGIN,
});
const DeleteMatterTypeSuccess = () => ({
    type: MatterTypes.DELETE_MATTER_TYPE_SUCCESS,
});
const DeleteMatterTypeError = () => ({
    type: MatterTypes.DELETE_MATTER_TYPE_ERROR,
});

export const DeleteMatterType = (matterTypeId, onSuccess) => async (dispatch) => {
    dispatch(DeleteMatterTypeBegin());

    try {
        await WebClient.delete(`/matter-types/${matterTypeId}`);
        dispatch(DeleteMatterTypeSuccess());
        dispatch(GetMatterTypes()); // refresh matter type list
        dispatch(GetMatterTypeColors());
        dispatch(GetPaginatedMatterTypes());
        dispatch(GetAppConfig()); // keeps our "options" list current for creating new matters
        dispatch(APISuccess('Matter Type successfully deleted.'));
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError('Error deleting matter type.'));
        dispatch(DeleteMatterTypeError());
    }
};

// END MATTER TYPE MANAGEMENT

// Admin only function
const GetActivityBegin = () => ({
    type: MatterTypes.GET_ACTIVITY_BEGIN,
});
const GetActivitySuccess = (payload) => ({
    type: MatterTypes.GET_ACTIVITY_SUCCESS,
    payload,
});
const GetActivityError = () => ({
    type: MatterTypes.GET_ACTIVITY_ERROR,
});

export const GetActivity = () => async (dispatch) => {
    dispatch(GetActivityBegin());
    try {
        const { data } = await WebClient.get('/activity-log');
        dispatch(GetActivitySuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetActivityError());
    }
};

const GetMattersCountBegin = () => ({
    type: MatterTypes.GET_MATTERS_COUNT_BEGIN,
});
const GetMattersCountSuccess = (payload) => ({
    type: MatterTypes.GET_MATTERS_COUNT_SUCCESS,
    payload,
});
const GetMattersCountError = () => ({
    type: MatterTypes.GET_MATTERS_COUNT_ERROR,
});

export const GetMattersCount = () => async (dispatch) => {
    dispatch(GetMattersCountBegin());
    try {
        const { data } = await WebClient.get('/matters/counts');
        dispatch(GetMattersCountSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetMattersCountError());
    }
};

const GetPaginatedMattersBegin = () => ({
    type: MatterTypes.GET_PAGINATED_MATTERS_BEGIN,
});
const GetPaginatedMattersSuccess = (payload) => ({
    type: MatterTypes.GET_PAGINATED_MATTERS_SUCCESS,
    payload,
});
const GetPaginatedMattersError = () => ({
    type: MatterTypes.GET_PAGINATED_MATTERS_ERROR,
});

export const GetPaginatedMatters = () => async (dispatch, getState) => {
    dispatch(GetPaginatedMattersBegin());

    const paginatedMattersParams = getPaginatedMattersParams(getState());
    const payload = cloneDeep(paginatedMattersParams);
    delete payload.page;
    delete payload.pageSize;
    delete payload.results;
    delete payload.total;
    delete payload.filter.stagesInternal; // used to persist stages filter

    try {
        const { data } = await WebClient.post(`/matters/${paginatedMattersParams.page}/${paginatedMattersParams.pageSize}`, payload);
        dispatch(GetPaginatedMattersSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetPaginatedMattersError());
    }
};

const DeleteMatterBegin = () => ({
    type: MatterTypes.DELETE_MATTER_BEGIN,
});
const DeleteMatterSuccess = () => ({
    type: MatterTypes.DELETE_MATTER_SUCCESS,
});
const DeleteMatterError = () => ({
    type: MatterTypes.DELETE_MATTER_ERROR,
});

export const DeleteMatter = (matterId, onSuccess) => async (dispatch) => {
    dispatch(DeleteMatterBegin());

    try {
        await WebClient.delete(`/matters/${matterId}`);
        dispatch(DeleteMatterSuccess());
        dispatch(APISuccess('Matter successfully deleted.'));
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError('Error deleting matter.'));
        dispatch(DeleteMatterError());
    }
};

const MarkMatterReadyForReviewBegin = () => ({
    type: MatterTypes.MARK_MATTER_READY_FOR_REVIEW_BEGIN,
});
const MarkMatterReadyForReviewSuccess = () => ({
    type: MatterTypes.MARK_MATTER_READY_FOR_REVIEW_SUCCESS,
});
const MarkMatterReadyForReviewError = () => ({
    type: MatterTypes.MARK_MATTER_READY_FOR_REVIEW_ERROR,
});

export const MarkMatterReadyForReview = (matterId, onSuccess) => async (dispatch, getState) => {
    dispatch(MarkMatterReadyForReviewBegin());
    const userRole = getRole(getState());

    try {
        await WebClient.post(`/matters/${matterId}/under-review`);
        // don't need to force a reload of all matters because the onsuccess has a redirect to a page that will fetch
        dispatch(MarkMatterReadyForReviewSuccess());
        dispatch(GetMattersCount());
        dispatch(APISuccess('Matter successfully marked ready for review.'));
        if (userRole === 'lawyer') dispatch(GetUser());
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError('Error marking mattering ready for review.'));
        dispatch(MarkMatterReadyForReviewError());
    }
};

const MarkMatterClosedSuccess = () => ({
    type: MatterTypes.MARK_MATTER_CLOSED_SUCCESS,
});
const MatterOutcomesBegin = () => ({
    type: MatterTypes.MATTER_OUTCOMES_BEGIN,
});
const MatterOutcomesSuccess = () => ({
    type: MatterTypes.MATTER_OUTCOMES_SUCCESS,
});
const MatterOutcomesError = () => ({
    type: MatterTypes.MATTER_OUTCOMES_ERROR,
});

export const MatterOutcomes = (matterId, data, onSuccess) => async (dispatch) => {
    dispatch(MatterOutcomesBegin());

    try {
        await WebClient.post(`/matters/${matterId}/enter-outcome`, data);
        dispatch(MatterOutcomesSuccess());
        dispatch(GetPaginatedMatters()); // need to refresh data for matters
        dispatch(GetMattersCount());
        if (data.close) {
            dispatch(MarkMatterClosedSuccess());
            dispatch(APISuccess('Matter successfully closed.'));
        } else {
            dispatch(APISuccess('Matter outcome data successfully updated.'));
        }
        if (onSuccess) onSuccess();
    } catch (error) {
        if (data.close) {
            dispatch(APIError('Error marking mattering closed.'));
        } else {
            dispatch(APIError('Error updating outcome data.'));
        }
        dispatch(MatterOutcomesError());
    }
};

const MarkMatterOpenBegin = () => ({
    type: MatterTypes.MARK_MATTER_OPEN_BEGIN,
});
const MarkMatterOpenSuccess = () => ({
    type: MatterTypes.MARK_MATTER_OPEN_SUCCESS,
});
const MarkMatterOpenError = () => ({
    type: MatterTypes.MARK_MATTER_OPEN_ERROR,
});

export const MarkMatterOpen = (matterId, onSuccess) => async (dispatch) => {
    dispatch(MarkMatterOpenBegin());

    try {
        await WebClient.post(`/matters/${matterId}/reopen`);
        // don't need to force a reload of all matters because the onsuccess has a redirect to a page that will fetch
        dispatch(MarkMatterOpenSuccess());
        dispatch(GetMattersCount());
        dispatch(APISuccess('Matter has been re-opened and moved to the Open Matters page.'));
        dispatch(GetPaginatedMatters()); // need to refresh data for matters
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError('Error marking mattering open.'));
        dispatch(MarkMatterOpenError());
    }
};

const DownloadMatterDocumentsBegin = () => ({
    type: MatterTypes.DOWNLOAD_DOCUMENTS_BEGIN,
});
const DownloadMatterDocumentsSuccess = () => ({
    type: MatterTypes.DOWNLOAD_DOCUMENTS_SUCCESS,
});
const DownloadMatterDocumentsError = () => ({
    type: MatterTypes.DOWNLOAD_DOCUMENTS_ERROR,
});

export const DownloadMatterDocuments = (matterId, onSuccess) => async (dispatch) => {
    dispatch(DownloadMatterDocumentsBegin());

    try {
        const { data } = await WebClient.get(`/matters/${matterId}/docs`);
        dispatch(DownloadMatterDocumentsSuccess());
        if (onSuccess) onSuccess(data.archiveUrl);
    } catch (error) {
        dispatch(APIError());
        dispatch(DownloadMatterDocumentsError());
    }
};

const AddNoteBegin = () => ({
    type: MatterTypes.ADD_NOTE_BEGIN,
});
const AddNoteSuccess = () => ({
    type: MatterTypes.ADD_NOTE_SUCCESS,
});
const AddNoteError = () => ({
    type: MatterTypes.ADD_NOTE_ERROR,
});

export const AddNote = (matterId, note) => async (dispatch) => {
    dispatch(AddNoteBegin());

    try {
        await WebClient.post('/notes', { matterId, note });
        dispatch(AddNoteSuccess());
        dispatch(GetMatterFull(matterId));
    } catch (error) {
        dispatch(APIError());
        dispatch(AddNoteError());
    }
};

const RequestDocumentBegin = () => ({
    type: MatterTypes.REQUEST_DOCUMENT_BEGIN,
});
const RequestDocumentSuccess = () => ({
    type: MatterTypes.REQUEST_DOCUMENT_SUCCESS,
});
const RequestDocumentError = () => ({
    type: MatterTypes.REQUEST_DOCUMENT_ERROR,
});

export const RequestDocument = (matterId, message) => async (dispatch) => {
    dispatch(RequestDocumentBegin());

    try {
        await WebClient.post(`/matters/${matterId}/request-doc`, { message });
        dispatch(RequestDocumentSuccess());
        dispatch(GetMatterFull(matterId));
    } catch (error) {
        dispatch(APIError());
        dispatch(RequestDocumentError());
    }
};

const UpdateMatterStageBegin = () => ({
    type: MatterTypes.UPDATE_MATTER_STAGE_BEGIN,
});
const UpdateMatterStageSuccess = (payload) => ({
    type: MatterTypes.UPDATE_MATTER_STAGE_SUCCESS,
    payload,
});
const UpdateMatterStageError = () => ({
    type: MatterTypes.UPDATE_MATTER_STAGE_ERROR,
});

const DocumentsUploadedBegin = () => ({
    type: MatterTypes.DOCUMENTS_UPLOADED_BEGIN,
});
const DocumentsUploadedSuccess = () => ({
    type: MatterTypes.DOCUMENTS_UPLOADED_SUCCESS,
});

const EditMatterBegin = () => ({
    type: MatterTypes.EDIT_MATTER_BEGIN,
});
const EditMatterSuccess = () => ({
    type: MatterTypes.EDIT_MATTER_SUCCESS,
});
const EditMatterError = () => ({
    type: MatterTypes.EDIT_MATTER_ERROR,
});

export const EditMatter = (matterId, data, updateType) => async (dispatch) => {
    if (updateType === 'documents') dispatch(DocumentsUploadedBegin());
    if (updateType === 'stage') dispatch(UpdateMatterStageBegin());
    dispatch(EditMatterBegin());
    const payload = data;

    if (data.documents && data.documents.length > 0) {
        const docPayload = [...data.documents];
        payload.documents = docPayload.filter((d) => (d.id && d.status !== 'error'))
            .map((d) => ({ id: d.id, name: d.name, title: d.title }));
    }

    try {
        await WebClient.patch(`/matters/${matterId}`, payload);
        dispatch(EditMatterSuccess());
        dispatch(GetActivity());
        if (updateType === 'documents') {
            dispatch(APISuccess('File(s) successfully uploaded.'));
            dispatch(DocumentsUploadedSuccess());
        } else if (updateType === 'stage') {
            // we don't want to refresh the matter when the stage is updated
            // we manage the data update through this success call
            // -update 11/10/22, we nede the activity log to be updated, so we need to fetch the full
            // matter until we decouple that data from the getFull updated
            dispatch(UpdateMatterStageSuccess(data));
            dispatch(GetMattersCount());
        } else {
            // dispatch(APISuccess(''));
        }
        dispatch(GetMatterFull(matterId));
    } catch (error) {
        dispatch(EditMatterError());
        if (updateType === 'documents') {
            dispatch(APIError('Error uploading file(s).'));
        } else if (updateType === 'stage') {
            dispatch(UpdateMatterStageError());
            dispatch(APIError('Error update stage'));
        } else {
            dispatch(APIError());
        }
    }
};

const PublishMatterBegin = () => ({
    type: MatterTypes.PUBLISH_MATTER_BEGIN,
});
const PublishMatterSuccess = () => ({
    type: MatterTypes.PUBLISH_MATTER_SUCCESS,
});
const PublishMatterError = () => ({
    type: MatterTypes.PUBLISH_MATTER_ERROR,
});

export const PublishMatter = (onSuccess, onError) => async (dispatch, getState) => {
    dispatch(PublishMatterBegin());
    const createMatterFormData = getCreateMatterFormData(getState());
    const payload = {
        matterTypeId: createMatterFormData.matter.matterTypeId,
        matterNumber: createMatterFormData.matter.matterNumber,
        partnerId: createMatterFormData.matter.partnerId,
        referrerId: createMatterFormData.matter.referrerId,
        creationDate: createMatterFormData.matter.creationDate,
        applicant: {
            email: createMatterFormData.applicant.email,
            firstName: createMatterFormData.applicant.firstName,
            middleName: createMatterFormData.applicant.middleName,
            lastName: createMatterFormData.applicant.lastName,
            otherName: createMatterFormData.applicant.otherName,
            dob: createMatterFormData.applicant.dob,
            city: createMatterFormData.applicant.city,
            state: createMatterFormData.applicant.state,
            countryOfOrigin: createMatterFormData.applicant.countryOfOrigin,
            phone: createMatterFormData.applicant.phone,
        },
    };

    if (createMatterFormData.documents && createMatterFormData.documents.length > 0) {
        const docPayload = [...createMatterFormData.documents];

        payload.documents = docPayload.filter((d) => (d.id && d.status !== 'error'))
            .map((d) => ({ id: d.id, name: d.name, title: d.title }));
    }

    try {
        await WebClient.post('/matters', removeEmpty(payload));
        dispatch(GetActivity());
        dispatch(GetMattersCount()); // update admin dashboard component
        dispatch(PublishMatterSuccess());
        dispatch(GetPaginatedMatters());
        dispatch(APISuccess('New matter successfully created.'));
        if (onSuccess) onSuccess();
    } catch (error) {
        if (onError) onError();
        dispatch(APIError('Error creating new matter.'));
        dispatch(PublishMatterError());
    }
};

export const CreateMatterUpdate = (payload) => ({
    type: MatterTypes.CREATE_MATTER_UPDATE,
    payload,
});

export const ResetMatterUpdate = () => ({
    type: MatterTypes.RESET_MATTER_UPDATE,
});

// LegalServer import
const SearchLegalServerBegin = () => ({
    type: MatterTypes.SEARCH_LEGAL_SERVER_BEGIN,
});
const SearchLegalServerSuccess = () => ({
    type: MatterTypes.SEARCH_LEGAL_SERVER_SUCCESS,
});
const SearchLegalServerError = () => ({
    type: MatterTypes.SEARCH_LEGAL_SERVER_ERROR,
});

export const SearchLegalServer = (searchTerms, pageNumber, pageSize, onSuccess, onError) => async (dispatch) => {
    dispatch(SearchLegalServerBegin());

    const payload = {
        ...searchTerms,
        results: 'full',
        pageNumber,
        pageSize,
    };

    try {
        const { data } = await WebClient.post('/legalserver/search', payload);
        dispatch(SearchLegalServerSuccess());
        if (data.invalid_values) {
            dispatch(APIError('Invalid search value.'));
            if (onSuccess) {
                onSuccess({
                    page_number: 1,
                    page_size: 20,
                    total_number_of_pages: 0,
                    total_records: 0,
                    data: [],
                });
            }
        } else if (onSuccess) onSuccess(data);
    } catch (error) {
        if (onError) onError();
        dispatch(APIError('Error searching LegalServer.'));
        dispatch(SearchLegalServerError());
    }
};

const ImportLegalServerBegin = () => ({
    type: MatterTypes.IMPORT_LEGAL_SERVER_BEGIN,
});
const ImportLegalServerSuccess = () => ({
    type: MatterTypes.IMPORT_LEGAL_SERVER_SUCCESS,
});
const ImportLegalServerError = () => ({
    type: MatterTypes.IMPORT_LEGAL_SERVER_ERROR,
});

export const ImportLegalServer = (onSuccess, onError) => async (dispatch, getState) => {
    dispatch(ImportLegalServerBegin());
    const importLegalServerData = getImportLegalServerData(getState());

    const payload = {};
    payload.matterUuids = importLegalServerData.matterUuids;
    payload.batchVariables = importLegalServerData.batchVariables;
    try {
        const { data } = await WebClient.post('/matters/legal-server', removeEmpty(payload));
        dispatch(GetActivity());
        dispatch(GetMattersCount()); // update admin dashboard component
        dispatch(GetPaginatedMatters());

        const feedBackMsg = `${data.imported} matter${data.imported > 0 && 's'} successfully imported.`;

        if (data.conflicts) {
            const conflicts = data.impactedMatters.map((item) => {
                let statusLink = item.status;
                if (item.status === 'underReview') statusLink = 'review';
                return (
                    <li key={item.id}>
                        <a
                            href={`${process.env.PUBLIC_URL}/${protectedRouteDirectory}/${statusLink}-matters/view-matter/${item.id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Matter {item.id}
                        </a>
                    </li>
                );
            });
            dispatch(APIError(<>{feedBackMsg} <br /> {data.impactedMatters.length} matter(s) are linked to a client email address that already exists and have been imported with the existing client details. Please review the below matters and confirm the client information is correct.<ol>{conflicts}</ol></>, true));
        } else {
            if (data.imported > 1) dispatch(APISuccess(feedBackMsg));
            if (data.imported === 1) dispatch(APISuccess(feedBackMsg));
        }
        dispatch(ImportLegalServerSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        if (error?.response?.status === 400 || error?.response?.statusCode === 400) {
            dispatch(APIError(`Error - ${error?.response.data.message}`));
        } else {
            dispatch(APIError('Error importing new matters from LegalServer.'));
        }
        dispatch(ImportLegalServerError());
        if (onError) onError();
    }
};

export const ImportLegalServerUpdate = (payload) => ({
    type: MatterTypes.IMPORT_LEGAL_SERVER_UPDATE,
    payload,
});

export const ResetImportLegalServerUpdate = () => ({
    type: MatterTypes.RESET_IMPORT_LEGAL_SERVER_UPDATE,
});
// END LegalServer import

// import from CSV
const ImportMattersBegin = () => ({
    type: MatterTypes.IMPORT_MATTERS_BEGIN,
});
const ImportMattersSuccess = () => ({
    type: MatterTypes.IMPORT_MATTERS_SUCCESS,
});
const ImportMattersError = () => ({
    type: MatterTypes.IMPORT_MATTERS_ERROR,
});

export const ImportMatters = (onSuccess, onError) => async (dispatch, getState) => {
    dispatch(ImportMattersBegin());
    const importMattersFormData = getImportMattersFormData(getState());
    const createMatterFormQuestions = getCreateMatterFormQuestions(getState());

    const flipValueKeys = (obj) => {
        const ret = {};
        Object.keys(obj).forEach((key) => {
            if (key && obj[key]) {
                const questionPage = find(createMatterFormQuestions, { key }).page;
                if (questionPage !== 'matter') {
                    ret[obj[key]] = `${questionPage}.${key}`;
                } else ret[obj[key]] = key;
            }
        });
        return ret;
    };

    const payload = {};
    payload.id = importMattersFormData.id;
    payload.mapping = flipValueKeys(importMattersFormData.mapping);
    payload.batchVariables = importMattersFormData.batchVariables;
    try {
        const { data } = await WebClient.post('/matters/batch-import', removeEmpty(payload));
        dispatch(GetActivity());
        dispatch(GetMattersCount()); // update admin dashboard component

        const feedBackMsg = `${data.imported} matter${data.imported > 0 && 's'} successfully imported.`;

        if (data.conflicts) {
            const conflicts = data.impactedMatters.map((item) => {
                let statusLink = item.status;
                if (item.status === 'underReview') statusLink = 'review';
                return (
                    <li key={item.id}>
                        <a
                            href={`${process.env.PUBLIC_URL}/${protectedRouteDirectory}/${statusLink}-matters/view-matter/${item.id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Matter {item.id}
                        </a>
                    </li>
                );
            });
            dispatch(APIError(<>{feedBackMsg} <br /> {data.impactedMatters.length} matter(s) are linked to a client email address that already exists and have been imported with the existing client details. Please review the below matters and confirm the client information is correct.<ol>{conflicts}</ol></>, true));
        } else {
            if (data.imported > 1) dispatch(APISuccess(feedBackMsg));
            if (data.imported === 1) dispatch(APISuccess(feedBackMsg));
        }
        dispatch(ImportMattersSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        if (error?.response?.status === 400 || error?.response?.statusCode === 400) {
            dispatch(APIError(`Error - ${error?.response.data.message}`));
        } else {
            dispatch(APIError('Error importing new matters.'));
        }
        dispatch(ImportMattersError());
        if (onError) onError();
    }
};

export const ImportMattersUpdate = (payload) => ({
    type: MatterTypes.IMPORT_MATTERS_UPDATE,
    payload,
});

export const ResetImportMattersUpdate = () => ({
    type: MatterTypes.RESET_IMPORT_MATTERS_UPDATE,
});

export const UploadDocumentBegin = (payload) => ({
    type: MatterTypes.UPLOAD_BEGIN,
    payload,
});
export const UploadDocumentSuccess = (payload) => ({
    type: MatterTypes.UPLOAD_SUCCESS,
    payload,
});
export const UploadDocumentError = (payload) => ({
    type: MatterTypes.UPLOAD_ERROR,
    payload,
});

const UpdateDocumentBegin = () => ({
    type: MatterTypes.UPDATE_DOCUMENT_BEGIN,
});
const UpdateDocumentSuccess = () => ({
    type: MatterTypes.UPDATE_DOCUMENT_SUCCESS,
});
const UpdateDocumentError = () => ({
    type: MatterTypes.UPDATE_DOCUMENT_ERROR,
});

export const UpdateDocument = (documentId, payload) => async (dispatch) => {
    dispatch(UpdateDocumentBegin());
    try {
        await WebClient.patch(`/documents/${documentId}`, payload);
        dispatch(UpdateDocumentSuccess());
        dispatch(GetMatterFull(payload.matterId));
    } catch (error) {
        dispatch(APIError());
        dispatch(UpdateDocumentError());
    }
};

const DeleteDocumentBegin = () => ({
    type: MatterTypes.DELETE_DOCUMENT_BEGIN,
});
const DeleteDocumentSuccess = () => ({
    type: MatterTypes.DELETE_DOCUMENT_SUCCESS,
});
const DeleteDocumentError = () => ({
    type: MatterTypes.DELETE_DOCUMENT_ERROR,
});

export const DeleteDocument = (matterId, documentId) => async (dispatch) => {
    dispatch(DeleteDocumentBegin());

    try {
        await WebClient.delete(`/documents/${documentId}`);
        dispatch(DeleteDocumentSuccess());
        dispatch(GetMatterFull(matterId));
    } catch (error) {
        dispatch(APIError());
        dispatch(DeleteDocumentError());
    }
};

const GetRecommendedAttoreysBegin = () => ({
    type: MatterTypes.GET_RECCOMENDED_ATTORNEYS_BEGIN,
});
const GetRecommendedAttoreysSuccess = (payload) => ({
    type: MatterTypes.GET_RECCOMENDED_ATTORNEYS_SUCCESS,
    payload,
});
const GetRecommendedAttoreysError = () => ({
    type: MatterTypes.GET_RECCOMENDED_ATTORNEYS_ERROR,
});

export const GetRecommendedAttoreys = (matterId) => async (dispatch) => {
    dispatch(GetRecommendedAttoreysBegin());

    try {
        const { data } = await WebClient.get(`/matters/${matterId}/lawyers`);
        dispatch(GetRecommendedAttoreysSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetRecommendedAttoreysError());
    }
};

const AssignAttorneysBegin = () => ({
    type: MatterTypes.ASSIGN_ATTORNEYS_BEGIN,
});
const AssignAttorneysSuccess = () => ({
    type: MatterTypes.ASSIGN_ATTORNEYS_SUCCESS,
});
const AssignAttorneysError = () => ({
    type: MatterTypes.ASSIGN_ATTORNEYS_ERROR,
});

export const AssignAttorneys = (matterId, assignees, onSuccess) => async (dispatch) => {
    dispatch(AssignAttorneysBegin());

    try {
        await WebClient.patch(`/matters/${matterId}/reassign`, { assignees });
        dispatch(AssignAttorneysSuccess());
        dispatch(APISuccess('Matter assigned and sent to attorneys for completion.'));
        dispatch(GetMatterFull(matterId));
        // need to refresh who is assigned, etc
        dispatch(GetRecommendedAttoreys(matterId));
        dispatch(GetMattersCount());
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError('Error assigning attorney.'));
        dispatch(AssignAttorneysError());
    }
};

export const UpdatePaginatedMattersParams = (payload) => ({
    type: MatterTypes.UPDATE_PAGINATED_MATTERS_PARAMS,
    payload,
});

export const ClearPaginatedMatters = () => ({
    type: MatterTypes.CLEAR_PAGINATED_MATTERS,
});

export const ResetUploader = () => ({
    type: MatterTypes.RESET_UPLOAD,
});

export const SetupUploader = (payload) => ({
    type: MatterTypes.SETUP_UPLOADER,
    payload,
});

export const DeleteUpload = (payload) => ({
    type: MatterTypes.UPLOAD_DELETE,
    payload,
});

export const UploadDocument = (file, matterId, onSuccess, onError, listIndex) => async (dispatch) => {
    const payload = {
        name: file.name,
        uploadErrMsg: null,
        status: 'inprogress',
        listIndex,
    };
    dispatch(UploadDocumentBegin(payload));

    const [formData, config] = prepareContentType('form-data', { doc: file });
    if (matterId) formData.matterId = matterId;
    try {
        const uploadFile = await WebClient.post('/documents', formData, config);
        const payloadSuccess = {
            listIndex,
            id: uploadFile.data.id,
            name: file.name,
            title: file.name,
            status: 'success',
        };
        dispatch(UploadDocumentSuccess(payloadSuccess));
    } catch (error) {
        const payloadError = {
            listIndex,
            id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
            name: file.name,
            title: file.name,
            status: 'error',
            errMsg: error.message,
            // uploadStatus: 'error',
            uploadErrMsg: 'Failed',
        };
        dispatch(UploadDocumentError(payloadError));
    }
};

const GetColumnDataBegin = () => ({
    type: MatterTypes.GET_COLUMNS_BEGIN,
});
const GetColumnDataSuccess = (payload) => ({
    type: MatterTypes.GET_COLUMNS_SUCCESS,
    payload,
});
const GetColumnDataError = () => ({
    type: MatterTypes.GET_COLUMNS_ERROR,
});

export const GetColumnData = (file) => async (dispatch) => {
    dispatch(GetColumnDataBegin());
    const payload = {
        name: file.name,
        uploadErrMsg: null,
        status: 'inprogress',
        listIndex: 0,
    };
    dispatch(UploadDocumentBegin(payload));

    const [formData, config] = prepareContentType('form-data', { file });
    try {
        const { data } = await WebClient.post('/imports/columns', formData, config);
        dispatch(GetColumnDataSuccess(data));
        const payloadSuccess = {
            listIndex: payload.listIndex,
            id: data.id,
            name: payload.name,
            title: payload.name,
            status: 'success',
        };
        dispatch(UploadDocumentSuccess(payloadSuccess));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetColumnDataError());
        const payloadError = {
            listIndex: payload.listIndex,
            id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
            name: payload.name,
            title: payload.name,
            status: 'error',
            errMsg: error.message,
            // uploadStatus: 'error',
            uploadErrMsg: 'Failed',
        };
        dispatch(UploadDocumentError(payloadError));
    }
};
