import React, {
    lazy,
    Suspense,
    useState,
    useEffect,
    useLayoutEffect,
} from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Routes, Route, Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import useBrowserTracing from 'react-router-v6-instrumentation';
import { deepmerge } from '@mui/utils';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';

import { useSelector, useDispatch } from 'react-redux';

import ProgressSpinner from '../components/progress-spinner';

import { GetMatterFull } from '../actions/matters';
import { protectedRouteDirectory } from '../constants';
import { isAuthenticated } from '../selectors/auth';
import { getViewMatterId } from '../selectors/matters';
import { getAppConfigTheme, getConfigLoaded } from '../selectors/app';

import defaultTheme from '../styles/material-theme';
import InitializerOrgs from '../initializers/orgs';
import InitializerMatters from '../initializers/matters';
import InitializerUser from '../initializers/user';
import InitializerForms from '../initializers/forms';
import InitializerResources from '../initializers/resources';
import InitializerUsers from '../initializers/users';
import InitializerApp from '../initializers/app';

const PublicLayout = lazy(() => import('../layouts/public'));
const ProtectedLayout = lazy(() => import('../layouts/protected'));

const App = ({ store }) => {
    const dispatch = useDispatch();
    const browserTracing = useBrowserTracing();
    const isUserSignedIn = useSelector(isAuthenticated);
    const viewingMatterId = useSelector(getViewMatterId);
    const configLoaded = useSelector(getConfigLoaded);
    const [appTheme, setAppTheme] = useState(defaultTheme);
    const appConfigTheme = useSelector(getAppConfigTheme);
    const currentUrl = encodeURIComponent(`${window.location.pathname}${window.location.search}`);

    useEffect(() => {
        setAppTheme(createTheme(deepmerge(defaultTheme, appConfigTheme)));
    }, [defaultTheme, appConfigTheme]);

    useEffect(() => {
        if (process.env.REACT_APP_SENTRY_DSN) {
            Sentry.init({
                dsn: process.env.REACT_APP_SENTRY_DSN,
                environment: process.env.REACT_APP_SENTRY_ENV,
                integrations: [
                    browserTracing,
                    new CaptureConsoleIntegration(),
                ],

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                tracesSampleRate: 1.0,
            });
        }
    }, []);

    const rehydrateSignedURLs = () => {
        InitializerOrgs(store);
        // if the user is viewing a matter, refresh it
        if (viewingMatterId) {
            dispatch(GetMatterFull(viewingMatterId));
        }
        // refresh underlying resource, form, and user data
        InitializerResources(store);
        InitializerForms(store);
        InitializerUser(store);
    };

    useLayoutEffect(() => {
        if (isUserSignedIn) {
            InitializerOrgs(store);
            InitializerUser(store);
            InitializerMatters(store);
            InitializerUsers(store);
            InitializerApp(store); // fetch a new config
        }

        // rehydrate the app every hour
        const delay = 60 * 60 * 1000; // 1 hour in msec
        const intervaleTimer = setInterval(() => {
            rehydrateSignedURLs();
        }, delay);

        // clear interval when user logs out, or app is unmounted
        if (!isUserSignedIn) clearInterval(intervaleTimer);
        return () => clearInterval(intervaleTimer);
    }, [isUserSignedIn]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appTheme}>
                {!configLoaded && (
                    <ProgressSpinner
                        id="loader"
                        isLoading
                        ariaLabel="Loading Application"
                        valueText="Loading Virtual Clinic"
                    />
                )}
                {configLoaded && (
                    <Suspense fallback={<div />}>
                        {process.env.REACT_APP_ST && (
                            <Helmet>
                                <link rel="shortcut icon" href="/images/st-favicon.png" />
                            </Helmet>
                        )}
                        <Routes>
                            <Route
                                path={`/${protectedRouteDirectory}/*`}
                                element={isUserSignedIn ? <ProtectedLayout /> : <Navigate to={`/login?next=${currentUrl}`} />}
                            />
                            <Route
                                path="/*"
                                element={<PublicLayout />}
                            />
                        </Routes>
                    </Suspense>
                )}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

App.propTypes = {
    store: PropTypes.object.isRequired,
};

export default App;
