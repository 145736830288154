import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

const getUsersState = (state) => state.users;

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual,
);

export const getAllAttorneyUsers = createSelector(
    getUsersState,
    (usersState) => usersState.allAttorneyUsers,
);

export const getPaginatedUsers = createSelector(
    getUsersState,
    (usersState) => usersState.paginatedUsers.results,
);

// used to present 'getPaginatedMatterParams' with a cleaned state to compare
const cleanPaginatedUsersParams = createSelector(
    getUsersState,
    (usersState) => {
        const cleanedParams = { ...usersState.paginatedUsers.params };
        return cleanedParams;
    },
);

// uses lodash.isequal to compare state of the object
export const getPaginatedUsersParams = createDeepEqualSelector(
    cleanPaginatedUsersParams,
    (cleanedUsersState) => cleanedUsersState,
);

export const getTotalUsersCount = createSelector(
    getUsersState,
    (usersState) => usersState.paginatedUsers.total,
);
