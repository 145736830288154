import AppTypes from '../action-types/app';

const internals = {
    initial: () => ({
        configLoaded: false,
        apiStatus: {
            error: false,
            errorMsg: null,
            completed: false,
            successMsg: null,
        },
        config: {
            colors: {
                primary: {
                    main: '#000000',
                    light: '#000000',
                    accent: '#000000',
                    hover: '#000000',
                },
                secondary: {
                    main: '#000000', // not being used currently
                    light: '#000000',
                },
            },
            typography: {
                color: '#000000',
                // fontFamily: "'Mulish', sans-serif",
            },
            userRoles: {},
            contactInfo: {
                email: '', // used for footer inside authenticated pages
            },
            createMatterFormQuestions: [],
            images: {},
            authorizedFeatures: {
                import: true,
                matterStages: true,
            },
        },
        privacy: {},
        terms: {},
        applicantTerms: {},
        mobileNavOpen: false,
        // used in profile management
        jurisdictions: [],
        languages: [],
    }),
};

const AppReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case AppTypes.OPEN_CLOSE_MOBILE_NAVIGATION: {
        let navOpen;
        if (payload !== null) navOpen = payload;
        else navOpen = !state.mobileNavOpen;
        return {
            ...state,
            mobileNavOpen: navOpen,
        };
    }
    case AppTypes.API_RESET:
        return {
            ...state,
            apiStatus: {
                error: false,
                errorMsg: null,
                autoHideDisabled: null,
                completed: false,
                successMsg: null,
            },
        };
    case AppTypes.API_ERROR:
        return {
            ...state,
            apiStatus: {
                error: true,
                errorMsg: payload.text,
                autoHideDisabled: payload.autoHideDisabled,
                completed: true,
                successMsg: null,
            },
        };
    case AppTypes.API_SUCCESS:
        return {
            ...state,
            apiStatus: {
                error: false,
                errorMsg: null,
                autoHideDisabled: payload.autoHideDisabled,
                completed: true,
                successMsg: payload.text,
            },
        };
    // case AppTypes.GET_APP_CONFIG_BEGIN: // this was causing a flashing of old colors during login process
    case AppTypes.GET_APP_CONFIG_ERROR:
        return {
            ...state,
            config: internals.initial().config,
        };
    case AppTypes.GET_APP_CONFIG_SUCCESS: {
        const { config, ...rest } = payload;
        return {
            ...state,
            ...rest,
            config,
            configLoaded: true,
        };
    }
    case AppTypes.SAVE_ADMIN_CONTENT_SUCCESS: {
        return {
            ...state,
            [payload.type]: payload.data,
        };
    }
    case AppTypes.GET_LANGUAGES_SUCCESS: {
        return {
            ...state,
            languages: payload.map((item) => ({
                id: item.id,
                language: item.language,
                name: item.language,
            })),
        };
    }
    case AppTypes.GET_JURISDICTIONS_SUCCESS: {
        return {
            ...state,
            jurisdictions: payload.map((item) => ({
                id: item.id,
                jurisdiction: item.jurisdiction,
                name: item.jurisdiction,
            })),
        };
    }
    default:
        // do nothing
    }
    return state;
};

export default AppReducer;
